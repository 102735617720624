import React, { useState, useLayoutEffect } from "react"
import styled from "@emotion/styled";
import { css } from "@emotion/react"
import { color } from "../styles/theme";
import mq from "../styles/mediaquerys";
import Closeicon from "../assets/svg/close.svg"
import { bannerActive, setBannerStorage } from '../../../services/banner';
import Link from "../../link"
import Flags from '../../../data/raw/flags';


const NotificationEl = styled.section`
    width: 100%;
    background: ${color.cta_green};
    /* background: ${color.main_light}; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${color.accent_light};
    height: 2.5rem;
    p,a {
        font-size: .8rem;
        color: ${color.main_dark};
        /* color: white; */
        margin: 0;
        font-weight: 600;
        letter-spacing: .04em;
        text-decoration: none;
    }
    span { /* was a before*/
        text-decoration: none;
        &:before {
            content: "";
            border-left: 1px solid black;
            height: 16px;
            line-height: 1;
            margin-left: 20px;
            margin-right: 20px;
            vertical-align: middle;
            opacity: .1;
            display: none;
            ${mq[2]} {
                display: inline-block;
            }
        }
    }
    .mobile {
        display: inline-block;
        ${mq[2]} {
            display: none;
        }
    }
    .nomobile {
        display: none;
        ${mq[2]} {
            display: inline-block;
        }
    }

`

export const CloseButton = ({className, action}) => {
    return(
        <button onClick={action} className={className} css={css`
            background: none;
            border: none;
            display: inline-flex;
            color: ${color.main_dark};
            position: absolute;
            right: 20px;
            cursor: pointer;
            opacity: .2;
        `}>
            <Closeicon css={css`
                height: 1.2rem;
                width: 1.2rem;
                fill: ${color.main_dark};
            `} />
        </button>
    )
}

const SABar = () => {
    const [bannerState, setBannerState] = useState(true)

    const handleBannerState = () => {
        setBannerState(bannerState => !bannerState )
        setBannerStorage(false)
    }

    useLayoutEffect(() => {
        const updateBannerState = () => {
            if (bannerActive() === false) {
                setBannerState(bannerState => !bannerState)
            }
        }
        updateBannerState()
    }, [])

    if( bannerState === true) {
        return (
          <NotificationEl>
            {Flags.features.livestream ? (
                <Link to="/live">
                    Join the <b>EXPO 9</b> live stream
                </Link>)
            : Flags.features.rearview ? (
                <Link to="/rearview">
                    <b>EXPO 9</b> rearview is now available
                </Link>)
            : (<p>Thanks for being part of EXPO 9</p>)}
            <span></span>
            <Link className="nomobile" to="https://startup-autobahn.com">a STARTUP AUTOBAHN powered by Plug and Play event</Link>
            <CloseButton action={handleBannerState} />
          </NotificationEl>
        )
    } else {
        return <></>
    }
}

export default SABar;