import React from "react"
import { css } from "@emotion/react";
import { color } from "./defaults/styles/theme";
import { wrapper, TitleHeader } from "./defaults/styles/elements";
import mq from "./defaults/styles/mediaquerys";
import { ArrowLink } from "../components/defaults/styles/elements";
import Ticket from './defaults/assets/svg/ticket.svg';
import Link from './link';


const styleButton = css`
    background: ${color.cta_green};
    color: ${color.accent_light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 2rem;
    border-radius: 5px;
    border: none;
    outline: none;
    text-decoration: none;
    font-weight: 500;
    /* letter-spacing: 0.05rem; */
`

const RegisterElement = () => {
    return (
      <div
        css={css`
          background: ${color.accent_light};
          padding: 2rem;
          border-radius: 5px;
          max-width: 45rem;
          margin: 0 auto;
          z-index: 1;
          position: relative;
        `}
      >
        <div
          css={css`
            /* flex: 0 1.5 70%; */
          `}
        >
          <TitleHeader
            title="Registration now open"
            icon={<Ticket />}
            css={css`
              padding: 0;
              h2 {
                font-size: 1.2em;
              }
              svg {
                fill: white;
                stroke: none;
              }
            `}
          />
          <p
            css={css`
              font-size: 0.85rem;
            `}
          >
            If you are interested in joining the event click on the "Register Now"
            below and reserve yourself one of the free spots.
          </p>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1.4123 0 25%;
            align-items: flex-start;
            margin-top: 2em;
            ${mq[0]} {
              flex-direction: row;
              align-items: center;
            }
          `}
        >
          <Link
            css={[
              styleButton,
              css`
                &:hover {
                  cursor: pointer;
                }
              `,
            ]}
            // className="typeform-share button"
            // href="https://form.typeform.com/to/USZTzCEv"
            // target="_blank"
            // rel="noreferrer"
            to="/register"
          >
            Register Now
          </Link>
          <ArrowLink
            url="/archive"
            text="Expo Rearview"
            css={css`
              margin: 2em 0 0;
              line-height: 1em;
              color: ${color.main_dark};
              ${mq[0]} {
                margin: 0 0 0 2em;
              }
              svg {
                stroke: ${color.main_dark};
              }
            `}
          />
        </div>
      </div>
    )
}

const RegisterNow = ({...props}) => {
    //  useEffect(() => {
    //    ;(function () {
    //      var //qs,
    //        js,
    //        q,
    //        //s,
    //        d = document,
    //        gi = d.getElementById,
    //        ce = d.createElement,
    //        gt = d.getElementsByTagName,
    //        id = "typef_orm_share",
    //        b = "https://embed.typeform.com/"
    //      if (!gi.call(d, id)) {
    //        js = ce.call(d, "script")
    //        js.id = id
    //        js.src = b + "embed.js"
    //        q = gt.call(d, "script")[0]
    //        q.parentNode.insertBefore(js, q)
    //      }
    //    })()
    //  }, []) 

    return (
      <>
        <section
          id="register"
          css={css`
            background: ${props.theme};
            position: relative;
            &:after {
              content: "";
              background: white;
              position: absolute;
              bottom: 0;
              left: 0;
              height: 25%;
              width: 100%;
              z-index: 0;
            }
            &:before {
              content: "";
              background: ${color.main_dark};
              position: absolute;
              bottom: 25%;
              left: 0;
              height: 1px;
              width: 100%;
              opacity: 0.08;
              z-index: 0;
            }
          `}
        >
          <div css={[wrapper]}>
            <RegisterElement />
          </div>
        </section>
      </>
    )
}

export default RegisterNow;