import React from "react";
import { Pnp } from '../defaults/assets/logos/partnerlogos/index'
import { 
    Adasky,
    Agevolt,
    Forciot,
    Holoride,
    Maaind,
    Motiongestures,
    Ottopia,
    Outforspace,
    Qualitymatch,
    Repairfix,
    Sevend,
    Spark,
    Terranet,
    Travelloc,
    Vfunction
  } from '../defaults/assets/logos/startuplogos/index';

  const StartupIcons = {
    recj874556mbDfs0n: Adasky,
    recwXIfTg1bxJlrM0: Agevolt,
    recZ3zcj3b3rm1AWF: Forciot,
    recQHJUTzE05CPvLY: Holoride,
    recqLhTedMHNKiz2g: Maaind,
    reczdzbPfQCaVpwK7: Motiongestures,
    recAICm63gCDulbDZ: Ottopia,
    recVxnuK1C4moonlm: Outforspace,
    recmDQLZkvg3GfJTr: Qualitymatch,
    recS2gSrGZ2yLT4Ln: Repairfix,
    rec5zUeDyyKlqBe2Q: Sevend,
    recWCu8NocxDInYFf: Spark,
    rec8aH0TATpd39SxF: Travelloc,
    recFHUbkMOmHFMd20: Terranet,
    rectuRYBiDhRRRaAS: Vfunction
  }

  const StartupLogo = ({ id }) => {
    let Icon = undefined

    if (StartupIcons[id] !== undefined) {
      Icon = StartupIcons[id]
    } else {
      Icon = Pnp
    }
    return <>{Icon ? <Icon /> : ""}</>
  }

  export default StartupLogo;