import React, { useState, useEffect } from 'react';
import RegisterNow from './registernow';
import FooterBox from './template_footer_element';
import Flags from '../data/raw/flags';
import { isLoggedIn } from '../services/auth';
import Send from '../components/defaults/assets/svg/send.svg';
import Zap from '../components/defaults/assets/svg/zap.svg';
import { Archive } from '../components/defaults/assets/svg/mobilenav';


const expo10 = {
  title: "Our upcoming EXPO 10",
  description: "Can’t get enough of STARTUP AUTOBAHN? Apply now for your chance to participate this summer as we bring together innovators from around the world, showcasing new technologies and celebrating our EXPO 10.",
  icon: <Zap/>,
  destination: {
    title: "Express Interest",
    url: "https://pnpgermany.typeform.com/to/RB0nRVxD",
    icon: <Send />,
  },
  sub: {
    title: "Expo Rearview",
    url: "/archive",
    icon: <Archive />,
  }
}

const GeneralFooter = ({...params}) => {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    setLoggedIn( isLoggedIn() )
  }, []);

  if ( (loggedIn || Flags.features.live || Flags.features.rearview) && expo10 ) {
    return (
      <FooterBox content={expo10} {...params} />
    )
  } else {
    return (
      <RegisterNow {...params} />
    )
  }
}

export default GeneralFooter;