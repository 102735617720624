import React from "react";

import {
  Adac,
  Agc,
  Arena2036,
  Basf,
  Bleistahl,
  Bosch,
  Bp,
  Covea,
  Daimler,
  Dpdhl,
  Dxc,
  Eberspaecher,
  Faurecia,
  Hyundai,
  Jardines,
  Linde,
  Maxion,
  Motherson,
  Murata,
  Novelis,
  Plasticomnium,
  Pnp,
  Porsche,
  Sekisui,
  Tsystems,
  Tuev,
  Unistuttgart,
  Webasto,
  Wieland,
  Zenzic,
  Zf,
} from "../defaults/assets/logos/partnerlogos/index"

 const PartnerIcons = {
   recc8PZqHaCiw47m8: Adac,
   rec57fTSx5qI3eri2: Agc,
   rec5B13OSughbrpRI: Arena2036,
   recpI0U9F6OLj2Z5U: Basf,
   recBuVQIzlK8jngZk: Bleistahl,
   rectjkazGfVOiZ6Mn: Bosch,
   rec6pUkC2EJGu1FQS: Bp,
   recvwINMyg3wTamcx: Covea,
   recnVHeZT7TLBInNX: Daimler,
   rec20GBdGUQZuuCk3: Dpdhl,
   recCQxinzRMsRbHD4: Dxc,
   recya4LCsorEjbJXi: Eberspaecher,
   recrhT8rRmwLuyTG8: Faurecia,
   recSDW2JjMBRgYDtx: Hyundai,
   recISMEiAyZjKbwO5: Jardines,
   recszuXjur152T8pf: Linde,
   recC1DDh6lZb2CPlp: Maxion,
   recoqVPU8UNKeMom0: Motherson,
   recezWtNIXOGtxayM: Murata,
   recZtvVKGloA5AF8K: Novelis,
   recrcUU30s8cCU0lr: Plasticomnium,
   recNAWwiCjRGts3JJ: Pnp,
   recL5RggtiYEQjvDQ: Porsche,
   recjIihFR1StyDwQt: Sekisui,
   recIJnFiipnI95PT5: Tsystems,
   rec3Rb64RvL2TPr5l: Tuev,
   reccnKtJCJEcKuwnb: Unistuttgart,
   rec7w9EuLhUHP6z0Y: Webasto,
   recgZ18vhkJGWYHOo: Wieland,
   recMyMQhwQv5I2V3D: Zenzic,
   recZVYgjqJ7lEmWXf: Zf,
 }

 const PartnerLogo = ({id, className}) => {
     let Icon = undefined;
     
     if (PartnerIcons[id] !== undefined) {
         Icon = PartnerIcons[id]
     }
     return(
        <>
            { Icon ? (
                <Icon className={className} />
            ) : ''}
        </>
     )
 }

 export default PartnerLogo;