import React from 'react'

import Adasky_ from './adasky_pri_wht_rgb.svg'
import Agevolt_ from './agevolt_pri_wht_rgb.svg'
import Forciot_ from './forciot_pri_wht_rgb.svg'
import Holoride_ from './holoride_pri_wht_rgb.svg'
import Maaind_ from './maaind_pri_wht_rgb.svg'
import Motiongestures_ from './motiongestures_pri_wht_rgb.svg'
import Outforspace_ from './outforspace_pri_wht_rgb.svg'
import Qualitymatch_ from './qualitymatch_pri_wht_rgb.svg'
import Repairfix_ from './repairfix_pri_wht_rgb.svg'
import Sevend_ from './sevend_pri_wht_rgb.svg'
import Spark_ from './spark_pri_wht_rgb.svg'
import Terranet_ from './terranet_pri_wht_rgb.svg'
import Travelloc_ from './travelloc_pri_wht_rgb.svg'
import Vfunction_ from './vfunction_pri_wht_rgb.svg'
import Ottopia_ from './ottopia_pri_wht_rgb.svg'

export const Adasky = () => { return (<Adasky_ />) }; 
export const Agevolt = () => { return (<Agevolt_ />) }; 
export const Holoride = () => { return (<Holoride_ />) }; 
export const Spark = () => { return (<Spark_ />) }; 
export const Maaind = () => { return (<Maaind_ />) }; 
export const Motiongestures = () => { return (<Motiongestures_ />) }; 
export const Outforspace = () => { return (<Outforspace_ />) }; 
export const Qualitymatch = () => { return (<Qualitymatch_ />) }; 
export const Repairfix = () => { return (<Repairfix_ />) }; 
export const Sevend = () => { return (<Sevend_ />) }; 
export const Forciot = () => { return (<Forciot_ />) };
export const Terranet = () => { return (<Terranet_ /> )};
export const Travelloc = () => { return (<Travelloc_ />) }; 
export const Vfunction = () => { return (<Vfunction_ />) }; 
export const Ottopia = () => { return (<Ottopia_ />) };  