module.exports = {
  settings : {
    // ATTENTION
    // - make sure to also adapt the title of the overall routing page to the same as the one to be
    //   found under "url"
    // - adapt the route which can be found under "onCreatePage" in the gatsby-node.js file
    protectedArea : {
      url: "inside",
      title: "Members Area"
    }
  },
  features : {
    // if "anonymize"
    // - startup information will be replaced by dummy default content and hidden behind a blurred out layer
    // - startups are not active and cannot be clicked
    // if false
    // - startups are shown properly 
    // - the respective sub-pages cannot be accessed
    startups: true,
    // if people === true || people === "profile_only"
    // - who profile button on contact element
    // - show champion in corporate profile
    // - show startup employees on startup profile
    // - show contact in agenda elements
    // if people === true
    // - show executives in corporate profile
    // - show corporate attendees
    people: true,
    // if rearview:
    // - show REAЯVIEW element on startup profile
    // - show REAЯVIEW element on partner profile
    // - show REAЯVIEW element on people profile
    // - show REAЯVIEW element on event session sites
    rearview: true,
    // if allAccess:
    // - show button on navigation bar
    allAccess: true,
    // if agenda:
    // - show agenda items in peoples profile
    // - shows "speaker" and "host" badge on
    // - show "origins from" element on rearview
    agenda: true,
    testimonial: false,
    // if registration (not main registration):
    // - registration buttons will be visible on respective elements
    //   (see for handler: services/registration.js)
    registration: false,
    // if live:
    // - button on agenda elements will show "JOIN" as well
    // - buttons on event page will change color and join button under "MORE"
    //   will be activated
    // - bottom info box right above footer will be switched out according to setup in general_footer
    live: false,
    // if true:
    // -show livestream indicator in hero section
    livestream: false,
    // if true:
    // - show casestudy on startup (incl. download)
    // - show casestudy link on agenda
    // - show casestudy link on partner profile
    casestudy: true,
  }, 
}