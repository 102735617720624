import React, { useState, useRef } from "react"
import { Link } from 'gatsby';
import { css } from "@emotion/react";
import styled from "@emotion/styled"
import { color } from "../styles/theme";
import navigation_data from '../../../data/navigation_data';
import { Home, People, Loader, Smile, Default, Close, Menu, Archive } from '../assets/svg/mobilenav';
import Calendar from "../assets/svg/calendar.svg"
// import Info from '../assets/svg/info.svg';
import Video from '../assets/svg/video.svg';
import mq from '../styles/mediaquerys';
import MobileDrawer from './mobile_drawer';
// import { blink } from "./navigation"
import Flags from '../../../data/raw/flags';


const getProps = ({ isPartiallyCurrent, href, location }) => {
  return {
    ...(isPartiallyCurrent && href !== "/"
      ? {
          "data-active": true,
        }
      : href === "/" && location.pathname === "/"
      ? {
          "data-active": true,
        }
      : href === "/people/all" && location.pathname.includes("/people/")
      ? {
          "data-active": true,
        }
      : href === "/agenda" && location.pathname.includes(`/${Flags.settings.protectedArea.url}/agenda/`)
      ? {
          "data-active": true,
        }
      : href === "/startups" &&
        location.pathname.includes(`/${Flags.settings.protectedArea.url}/startups/`)
      ? {
          "data-active": true,
        }
      : {}),
  }
}

const Components = {
    Home: Home,
    Agenda: Calendar,
    People: People,
    Startups: Loader,
    Partners: Smile,
    Archive: Archive,
    Rearview: Video,
}

// const RegisterNotification = () => {
//     return (
//       <div
//         css={css`
//           position: absolute;
//           top: 0;
//           right: 0;
//           height: 0.95em;
//           line-height: 1em;
//           top: 0.35em;
//           right: 0.85em;
//           svg {
//             fill: ${Flags.features.livestream ? color.rec : color.cta_green};
//             stroke: ${Flags.features.livestream ? color.rec : color.main_dark};
//             animation: ${Flags.features.livestream ? css`${blink} 2s ease-in-out infinite` : "none"};
//             height: 100%;
//             width: auto;
//           }
//         `}
//       >
//         {Flags.features.livestream ? <Default /> : <Info />}
//       </div>
//     )
// }

const NavEl = ({item}) => {
    let Icon = Default;
    if ( Components[item.name] !== undefined ) {
        Icon = Components[item.name];
    }

    return(
        <>
            <Link to={item.url} getProps={getProps} activeClassName='active' css={css`
                color: ${color.main_dark};
                text-decoration: none;
                position: relative;
                &[data-active]:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: .15rem;
                    width: 100%;
                    background: ${color.main_dark};
                }
            `}>
                <div css={css`
                    width: 4rem;
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    overflow: hidden;
                    svg {
                        height: 1.3rem;
                        width: auto;
                        margin-bottom: 3px;
                    }
                `}>
                    <Icon />
                    <p css={css`
                        margin: 0;
                        font-size: .75em;
                    `}>
                        {item.name}
                    </p>
                </div>
            </Link>
        </>
    )
}

const NavButton = ({toggleDrawer, drawerActive}) => {
    return (
        <button onClick={toggleDrawer} css={css`
                color: ${color.main_dark};
                text-decoration: none;
                position: relative;
                border: none;
                background: none;
                outline: none;
                padding: 0;
                &:hover {
                    cursor: pointer;
                }
            `}>
            <div css={css`
                    width: 4rem;
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    overflow: hidden;
                    svg {
                        height: 1.3rem;
                        width: auto;
                        margin-bottom: 3px;
                    }
                `}>
                {drawerActive ? (<Close />): (<Menu />)}
                
                {/* <RegisterNotification /> */}

                <p css={css`
                    margin: 0;
                    font-size: .75em;
                `}>
                    Menu
                </p>
            </div>
        </button>   
    )
}


const Navigation = styled.nav`
    background: white;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    ${mq[2]} {
        display: none;
    }
    &:before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: 1px;
        width: 100%;
        opacity: .08;
        background: ${color.main_dark};
    }
`


const MobileNav = () => {
    const [drawerActive, setDrawerActive] = useState("");
    const [drawerHeight, setDrawerHeight] = useState('0px');
    const menuDrawer = useRef(null);

    function toggleDrawer() {
        setDrawerActive( drawerActive === "" ? "active" : "");

        if (drawerActive === "active") {
            setDrawerHeight("0px")
        } else if ((menuDrawer.current.scrollHeight) < ((window.innerHeight - (4 * 16)) * 0.9) ) {
            setDrawerHeight(`${menuDrawer.current.scrollHeight}px`)
        } else {
            setDrawerHeight(((window.innerHeight - (4 * 16)) * 0.9) + 'px')
        }
    }

    return(
        <>
            <Navigation>
                {navigation_data.mobile.main.map((item, index) => (
                    <NavEl item={item} key={index}/>
                ))}
                <NavButton toggleDrawer={toggleDrawer} drawerActive={drawerActive} />
            </Navigation>
            <MobileDrawer drawerActive={drawerActive} menuDrawer={menuDrawer} drawerHeight={drawerHeight} toggleDrawer={toggleDrawer}  />
        </>
    )
}

export default MobileNav;