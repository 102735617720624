import React from "react"
import Adac_ from './adac_pri_blk_rgb.svg'
import Agc_ from './agc_pri_blk_rgb.svg'
import Arena2036_ from './arena2036_pri_blk_rgb.svg'
import Basf_ from './basf_pri_blk_rgb.svg'
import Bleistahl_ from './bleistahl_pri_blk_rgb.svg'
import Bosch_ from './bosch_pri_blk_rgb.svg'
import Bp_ from './bp_pri_rgb_blk.svg'
import Covea_ from './covea_pri_blk_rgb.svg'
import Daimler_ from './daimler_pri_blk_rgb.svg'
import Dpdhl_ from './dpdhl_pri_blk_rgb.svg'
import Dxc_ from './dxc_pri_blk_rgb.svg'
import Eberspaecher_ from './eberspaecher_pri_blk_rgb.svg'
import Faurecia_ from './faurecia_pri_blk_rgb.svg'
import Hyundai_ from './hyundai_pri_blk_rgb.svg'
import Jardines_ from './jardines_pri_blk_rgb.svg'
import Linde_ from './linde_pri_blk_rgb.svg'
import Maxion_ from './maxionwheels_pri_blk_rgb.svg'
import Motherson_ from './motherson_pri_blk_rgb.svg'
import Murata_ from './murata_pri_blk_rgb.svg'
import Novelis_ from './novelis_pri_blk_rgb.svg'
import Plasticomnium_ from './plasticomnium_pri_blk_rgb.svg'
import Pnp_ from './pnp_pri_blk_rgb.svg'
import Porsche_ from './porsche_pri_blk_rgb.svg'
import Rrps_ from './RollsRoyce_PS_black_rgb.svg'
import Sekisui_ from './sekisui_pri_blk_rgb.svg'
import Tsystems_ from './tsystems_pri_blk_rgb.svg'
import Tuev_ from './tuev_pri_blk_rgb.svg'
import Unistuttgart_ from './uni_pri_blk_rgb.svg'
import Webasto_ from './webasto_pri_blk_rgb.svg'
import Wieland_ from './wieland_pri_blk_rgb.svg'
import Zenzic_ from  './zenzic_pri_blk_rgb.svg'
import ZF_ from  './zf_pri_blk_rgb.svg'

export const Adac = () => { return( <Adac_ /> ) }; 
export const Agc = () => { return( <Agc_ /> ) }; 
export const Arena2036 = () => { return( <Arena2036_ /> ) }; 
export const Basf = () => { return( <Basf_ /> ) }; 
export const Bleistahl = () => { return( <Bleistahl_ /> ) }; 
export const Bosch = () => { return( <Bosch_ /> ) }; 
export const Bp = () => { return( <Bp_ /> ) }; 
export const Covea = () => { return( <Covea_ /> ) }; 
export const Daimler = () => { return( <Daimler_ /> ) }; 
export const Dpdhl = () => { return( <Dpdhl_ /> ) }; 
export const Dxc = () => { return( <Dxc_ /> ) }; 
export const Eberspaecher = () => { return( <Eberspaecher_ /> ) }; 
export const Faurecia = () => { return( <Faurecia_ /> ) }; 
export const Hyundai = () => { return( <Hyundai_ /> ) }; 
export const Jardines = () => { return( <Jardines_ /> ) }; 
export const Linde = () => { return( <Linde_ /> ) }; 
export const Maxion = () => { return( <Maxion_ /> ) }; 
export const Motherson = () => { return( <Motherson_ /> ) }; 
export const Murata = () => { return( <Murata_ /> ) }; 
export const Novelis = () => { return( <Novelis_ /> ) }; 
export const Plasticomnium = () => { return( <Plasticomnium_ /> ) }; 
export const Pnp = () => { return( <Pnp_ /> ) }; 
export const Porsche = () => { return( <Porsche_ /> ) }; 
export const Rrps = () => { return( <Rrps_ /> ) }; 
export const Sekisui = () => { return( <Sekisui_ /> ) }; 
export const Tsystems = () => { return( <Tsystems_ /> ) }; 
export const Tuev = () => { return( <Tuev_ /> ) }; 
export const Unistuttgart = () => { return( <Unistuttgart_ /> ) }; 
export const Webasto = () => { return( <Webasto_ /> ) }; 
export const Wieland = () => { return( <Wieland_ /> ) }; 
export const Zenzic = () => { return( <Zenzic_ /> ) }; 
export const Zf = () => { return( <ZF_ /> ) }; 
